import { number } from "prop-types";
import styled from "styled-components";
import Flex from "../FlexBox/Flex";

const RoundedBox = styled(Flex).attrs(props => ({
  w: props.w ?? 400,
  pt: props.pt ?? 1,
  pb: props.pb ?? 1,
}))`
  position: relative;
  background: ${props => props.background ?? "#FFFFFF"};
  box-shadow: ${props => (props.hideBoxShadow ? "none" : "0 10px 10px 0 rgba(0,0,0,0.10)")};
  border-radius: 10px;
  min-width: min-content;
  margin: 10px 0 10px 0;

  @media (max-width: 440px) {
    width: 100%;
    min-width: 350px;
  }

  @media (max-width: 374px) {
    width: 100%;
    min-width: 320px;
  }
`;

RoundedBox.propTypes = {
  w: number,
  mt: number,
  pt: number,
  pb: number,
};

export default RoundedBox;
