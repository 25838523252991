import { makeVar, useReactiveVar } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

/**
 * Define each sidebar
 */
const SIDEBAR_TYPES = {
  TEAM_DASHBOARD_VISIBILITY: "team-dashboard-visibility-sidebar",
  COACHING_VIEW_BUILDER: "coaching-view-builder-sidebar",
  PLAN_AND_BILLING_SIDEBAR: "plan-and-billing-sidebar",
  BULK_IMPORTER_QUICK_START_GUIDE: "bulk-importer-quick-start-guide-sidebar",
};

const SET_SIDEBAR_STATES = {
  [SIDEBAR_TYPES.TEAM_DASHBOARD_VISIBILITY]: null,
  [SIDEBAR_TYPES.PLAN_AND_BILLING_SIDEBAR]: null,
  [SIDEBAR_TYPES.COACHING_VIEW_BUILDER]: null,
  [SIDEBAR_TYPES.BULK_IMPORTER_QUICK_START_GUIDE]: null,
};

const getSidebarState = (type, initialState) => {
  if (!SET_SIDEBAR_STATES[type]) {
    SET_SIDEBAR_STATES[type] = makeVar(initialState);
  }

  return SET_SIDEBAR_STATES[type];
};

/**
 * Hook for managing sidebar visibility across the application
 * @returns [] - state, setState accessors for visibility of sidebar type
 */
const useSidebar = (type, initialState = false) => {
  const sidebar = Object.values(SIDEBAR_TYPES).find(t => t === type);

  if (!sidebar) {
    // eslint-disable-next-line no-console
    console.warn("useSidebar needs to be passed a valid sidebar type!");
  }

  const setState = getSidebarState(type, initialState);
  const state = useReactiveVar(setState);

  /**
   * Toggle the visibility state of the target sidebar,
   * automatically hide all other sidebars to prevent overlap
   */
  const toggleState = (optionalState) => {
    const incomingState = optionalState ?? !state;

    // Set or toggle current state of the target sidebar
    setState(incomingState);

    // Reset all other sidebars to hidden
    Object.values(SIDEBAR_TYPES).forEach((sb) => {
      if (type !== sb) {
        const setSidebarState = SET_SIDEBAR_STATES[sb];

        // Not all sidebars are guaranteed to be initialized
        setSidebarState?.(false);
      }
    });

    /**
     * Show/Hide the Intercom Messenger
     */
    try {
      if (typeof window?.Intercom !== "undefined") {
        // If showing a sidebar, hide the intercom messenger
        if (incomingState) {
          window.Intercom("update", { hide_default_launcher: true });
        }
        else { // hiding the sidebar, show the intercom messenger
          window.Intercom("update", { hide_default_launcher: false });
        }
      }
    }
    catch (error) {
      Sentry.captureException(error, {
        message: "Caught error toggling Intercom Messenger",
        level: "warning",
        tags: {
          function: "useSidebar",
        },
      });
    }
  };

  return [
    state,
    toggleState, // setState
  ];
};

export {
  SIDEBAR_TYPES,
  useSidebar,
};
