/**
 * next-translate configuration
 * @see https://github.com/aralroca/next-translate#add-i18njs-config-file
 */

module.exports = {
  "locales": ["en", "de", "es"],

  "defaultLocale": "en",

  "defaultNS": "common",

  "pages": {
    "*": [
      "common",
      "organization",
      "organization-configuration",
      "organization-invites",
      "organization-roles",
    ],
  },

  "loadLocaleFrom": (lang, ns) =>
    // You can use a dynamic import, fetch, whatever. You should
    // return a Promise with the JSON file.
    import(`./public/locales/${lang}/${ns}.json`).then((m) => m.default),
};
