import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import { Locale } from "@/lib";

const GET_AI_ENABLED_CHECK = gql`
  query getAiEnabledCheck {
    check {
      hasAiEnabled
    }
  }
`;

const useUserHasInsightSearch = () => {
  const router = useRouter();
  const { data } = useQuery(GET_AI_ENABLED_CHECK);

  const isEnglishLocale = Locale.English === router.locale;
  const hasInsightSearch = isEnglishLocale && data?.check?.hasAiEnabled;

  return { hasInsightSearch };
};

export { useUserHasInsightSearch };
