import React from "react";
import { DataTable as GrommetDataTable, Skeleton } from "grommet";
import { getThemeColor, themeStyle } from "@/cloverleaf-ui/theme";

const getLoadingData = (columns, rows = 6) => {
  const loadingColumns = columns.map((column) => ({
    ...column,
    render: () => <Skeleton height="24px" />,
  }));

  const emptyRow = columns.reduce((acc, { property }) => ({
    ...acc,
    [property]: undefined
  }), {});

  const loadingData = Array(rows).fill(emptyRow);

  return { loadingData, loadingColumns };
};

function DataTable({
  loading,
  columns,
  data,
  ...props
}) {
  const { loadingColumns, loadingData } = getLoadingData(columns);

  return (
    <GrommetDataTable
      tableStyles={{
        borderStyle: "solid",
        borderColor: getThemeColor("grey400"),
        borderWidth: themeStyle.global.edgeSize.hair,
        borderRadius: themeStyle.global.edgeSize.xxsmall,
      }}
      pad={{
        header: "medium",
        body: "medium",
      }}
      {...props}
      columns={loading ? loadingColumns : columns}
      data={loading ? loadingData : data}
    />
  );
}

export { DataTable };
